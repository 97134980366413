import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {

    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <ul className="mainmenu">
            

            <li className="has-droupdown"><Link to="/contacto">Contact</Link></li>
        </ul>
    )
}
export default Nav;
