import React from 'react';
import SEO from "../common/SEO";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import {Link} from "react-router-dom";
import HeaderOne from '../common/header/HeaderOne';
import CounterUpFour from '../elements/counterup/CounterUpFour';
import ServiceOne from '../elements/service/ServiceOne';
import AboutFour from '../elements/about/AboutFour';
import AdvancePricingOne from '../elements/advancePricing/AdvancePricingOne';
import BrandOne from '../elements/brand/BrandOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';


const BannerData = [
    {
        // image: "/images/bg/bg-image-20.jpg",
        image: "/images/bg/banner.png",
        title: "Tu Negocio <br /> Impulsado🚀",
        description: "Ayudamos a nuestros clientes a desarrollar su marca e identidad digital, <br /> a reflejar ideas en sistemas funcionales y útiles."
    },
]

const WebAgency = () => {
    return (
        <>
            <SEO title="Informática Superior" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" />


                {/* Start Slider Area  */}
                <div className="slider-area slider-style-8 height-650">
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">                             
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div className="inner text-center">
                                            <span className="subtitle theme-sec">Bienvenido a Informática Superior</span>
                                            <h1 className="title" dangerouslySetInnerHTML={{__html: data.title}}></h1>
                                            <p className="description" dangerouslySetInnerHTML={{__html: data.description}}></p>
                                            <div className="button-group mt--30">
                                                <a className="btn-default btn-large round" href="/contacto">Contáctenos</a>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                    ))}
                </div>
                {/* End Slider Area  */}

                <Separator />

                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">Te ayudamos al desarrollo digital de tu marca.</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">Potencia tu negocio con una presencia online que refleje la calidad de tus productos y servicios. Con un diseño atractivo y funcional, transformamos tu visión en una experiencia digital impactante que atrae y retiene a tus clientes.</p>
                                <div className="readmore-btn">
                                    <Link className="btn-read-more" to="/contacto"><span>Ver mas..</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start Service Area  */}
                <div id="services" className="rn-service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Que podemos hacer por vos"
                                    title = "Servicios pensados para nuestros clientes."
                                    description = ""
                                    />
                            </div>
                        </div>
                        <ServiceOne 
                            serviceStyle = "service__style--1 icon-circle-style"
                            textAlign = "text-center"
                            />
                    </div>
                </div>
                {/* End Service Area  */}

                <AboutFour image="./images/bg/banner.png" />

                {/* Start Elements Area  */}
                <div className="rwt-pricingtable-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Nuestros precios"
                                    title = "Web Corporativa."
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className="row mt--30">
                            <div className="col-lg-8 offset-lg-2">
                                <AdvancePricingOne />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Elements Area  */}

               
                <FooterTwo />   
                <Copyright />
            </main>
        </>
    )
}
export default WebAgency;

