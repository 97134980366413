import React from 'react'
import { FiCheck, FiStar } from "react-icons/fi";

const AdvancePricingOne = () => {
    return (
        <div className="advance-pricing">
            <div className="inner">
                <div className="row row--0">
                    <div className="col-lg-6">
                        <div className="pricing-left">
                            <h3 className="main-title">Web Corporativa + Logo.</h3>
                            <p className="description">La mejor opción para su empresa.</p>
                            <div className="price-wrapper">
                                <span className="price-amount">$179.000 </span>
                            </div>
                            <div className="pricing-btn-group">
                                <a className="btn-default" href="/contacto">Consultar Producto</a>
                            </div>
                            <div className="rating">
                                <a href="#rating"><FiStar /></a>
                                <a href="#rating"><FiStar /></a>
                                <a href="#rating"><FiStar /></a>
                                <a href="#rating"><FiStar /></a>
                                <a href="#rating"><FiStar /></a>
                            </div>
                            <span className="subtitle">Los costos de servidor quédan bonificados 6 meses.</span>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="pricing-right">
                            <div className="pricing-offer">
                                <div className="single-list">
                                    <h4 className="price-title">El precio incluye:</h4>
                                    <ul className="plan-offer-list">
                                        <li><FiCheck />  Sitio creado en React, HTML, CSS y JS</li>
                                        <li><FiCheck />  Imagenes pre-cargadas</li>
                                        <li><FiCheck />  Adaptable a cualquier dispositivo</li>
                                        <li><FiCheck />  Seo optimizado para aparecer en las busquedas</li>
                                        <li><FiCheck />  Enlace a redes sociales</li>
                                        <li><FiCheck />  Botón de Whatsapp</li>
                                        <li><FiCheck />  Certificado SSL</li>
                                        <li><FiCheck />  Hosting bonificado por 6 meses</li>
                                    </ul>
                                </div>
                                <div className="single-list mt--40">
                                    <h4 className="price-title">El diseño de logo incluye:</h4>
                                    <ul className="plan-offer-list">
                                        <li><FiCheck /> Tres propuestas</li>
                                        <li><FiCheck /> Ejemplos de aplicacion del logo</li>
                                        <li><FiCheck />  Paleta de colores</li>
                                        <li><FiCheck />  Archivo vectorizado de su logo</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdvancePricingOne
