import React from 'react';
import {FiTerminal, FiLayout, FiAperture} from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiLayout  />,
        title: 'Desarrollo Web',
        description: 'Creamos sitios web modernos y funcionales, diseñados para ofrecer la mejor experiencia de usuario y reflejar la calidad de tus productos y servicios.'
    },
    {
        icon: <FiAperture  />,
        title: 'Diseño logo y Creatividad para tu marca',
        description: 'Diseñamos logos y elementos visuales que capturan la esencia de tu marca, ayudándote a destacarte en el mercado y atraer a tu público objetivo.'
    },
    {
        icon: <FiTerminal  />,
        title: 'Desarrollo de WebApps',
        description: 'Desarrollamos aplicaciones web personalizadas que optimizan tus procesos y mejoran la interacción con tus clientes, adaptándonos a tus necesidades específicas.'
    },
]


const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;